$breakpoints: (
  '320': 320px,
  '375': 375px,
  'mobile': 568px,
  'tablet': 768px,
  'medium': 1024px,
  'smallHeader': 1100px,
  'large': 1400px,
) !default;

$emojiFonts: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$systemSansSerif:
        system-ui,
        -apple-system,
        "Segoe UI",
        Roboto,
        "Helvetica Neue", Arial,
        "Noto Sans", "Liberation Sans",
        sans-serif,
        $emojiFonts;


$LatoFont: 'Lato', $systemSansSerif;
$PoppinsFont: 'Poppins', $systemSansSerif;

$font-families: (
  poppins: $PoppinsFont,
  lato: $LatoFont,
) !default;

$container-width: 970px;

$activity-feed-breakpoint: 1279px;

//@fixme replace hardcoded width with padding or smth
$request-item-width: 940px;

@mixin media($breakpoint) {
  @if (map-has-key($breakpoints, $breakpoint)) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

@mixin min_media($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin retina($ratio: 2) {
  @media (-webkit-min-device-pixel-ratio: $ratio), (min-resolution: #{$ratio}dppx) {
    @content;
  }
}

@mixin webkitScroll {
  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.05);
  }
}

@mixin hide_scroll {
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox
  &::-webkit-scrollbar {
    display: none;
  }
}

$desktop-header-height: 70px;
$mobile-header-height: 50px;

$global-border-radius: 4px;
$input-radius: $global-border-radius;
$button-radius: $global-border-radius;
//================ Colors ===================\\
// used real color names
$primary: #0071BC; //0087d0
$primary-darker: #0090de;
$primary-darkest: #006ba7;
$dark-blue: #004266;

$cta: #ffd700;
$cta-darker: #ffdb1a;
$cta-darkest: #f3ce00;
$cta-shadow: rgba(255, 215, 0, 0.7);
$cta-disabled: #f6f6f6;
$cta-hover: #ffea00;
$cta-active: #f2ce00;

$green: #009e2f;
$amaranth: #e8394f;
$lima: #7ed321;

$error: #f02942;
$error-shadow: rgba(255, 123, 123, 0.7);

$locked-red: #ffe5e9;

$outline: #3b99fc;
$input-outline: #8ac4da;

$alert-success-background: #E9F9D7;
$alert-warning-background: #fff7e7;
$alert-error-background: #FFE5E9;
$alert-info-background: #e6f3fb;

$black: #000000;
$dark-grey: #414141;
$tundora: #4d4d4d;
$grey: #676767;
$grey-settings: #f6f6f6;
$grey-lightest: #fafafa;
$grey-e: #eeeeee;
$geyser: #ebebeb;
$mercury: #e6e6e6;
$alto: #dadada;
$silver-chalice: #a0a0a0;
$early-dawn: #fff7e7;
$white-ice: #e6f3fb;
$white: #ffffff;

$shadow-color-main: rgba(0, 0, 0, 0.09);
$shadow-color-secondary: rgba(0, 0, 0, 0.2);

$messengerColor: #0084ff;
$facebookColor: #3b5998;

//============== Elements' colors ===============\\
// Buttons
$btn-primary: $primary;
$btn-primary-hover: $primary-darker;
$btn-primary-active: $primary-darkest;
$btn-primary-disabled: $cta-disabled;
$btn-primary-disabled-text: $mercury;

$btn-cta: $cta;
$btn-cta-hover: $cta-darker;
$btn-cta-active: $cta-darkest;
$btn-cta-disabled: $cta-disabled;
$btn-cta-disabled: $cta-disabled;
$btn-cta-disabled-text: $mercury;

// Links
$link-color: $primary;
$link-color-hover: $dark-blue;
$link-color-active: $dark-blue;
$link-color-dark: $dark-grey;

// Errors
$error-color: $error;

// Backgrounds & borders
$banner-background-color: $cta;
$banner-background-color-grey: $grey-settings;
$background-light: $white;
$background-cta: $cta;
$background-normal: $geyser;
$background-provider: $grey-settings;
$background-primary: $primary;
$background-lightest: $grey-lightest;
$background-grey: $grey;
$background-message-own: $primary;
$background-message-enemy: $grey-e;
$body-background-light: $white;
$body-background-settings: $grey-settings;
$body-background: $grey-settings;
$body-background-provider: $grey-e;

$border-dark: $mercury;
$border-light: $geyser;
$border-cta: $cta;
$border-blue: $primary;
$focus-hover-border-color: $input-outline;

// Progress
$progress-primary: $primary;
// Labels
$label-color: $grey;
$finished-label: $green;
$label-stars: $early-dawn;
$label-direct: #e9f9d7;
$label-vas: $white-ice;
// Headings
$heading-color: $dark-grey;
$heading-color-light: $grey;
$subtitle-color: $grey;
// Text
$text-black: $black;
$text-white: $white;
$text-grey: $grey;
$text-dark-grey: $dark-grey;
$text-light-grey: $silver-chalice;
$text-primary: $primary;
$text-cta: $cta;
$text-placeholder: $silver-chalice;
$text-error: $error;
$text-disabled: $alto;
$text-success: $lima;

$badge-color: $amaranth;

// focus rings
$link-focus-ring-color: transparentize($link-color, 0.6);
$header-focus-ring-color: transparentize($background-cta, 0.3);

$conversation-block-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

// ============== Mixins ===============\\

@mixin focus_state($outlineColor: $link-focus-ring-color) {
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px $outlineColor;
    @content;
  }

  &:active {
    box-shadow: none;
  }
}

@mixin provider_header_focus_state {
  @include focus_state($header-focus-ring-color) {
    border-radius: 3px;
  }
}

// FONTS
@mixin font_params($fontSize, $lineHeight, $fontWeight: null, $color: null) {
  font-size: $fontSize;
  line-height: $lineHeight;

  @if $fontWeight {
    font-weight: $fontWeight;
  }
  @if $color {
    color: $color;
  }
}

@mixin lato_font($fontSize: 16px, $lineHeight: $fontSize * 1.5, $fontWeight: 400, $color: $text-dark-grey) {
  font-family: $LatoFont;
  @include font_params($fontSize, $lineHeight, $fontWeight, $color);
}

@mixin poppins_font($fontSize: 16px, $lineHeight: $fontSize * 1.5, $fontWeight: 400, $color: $text-dark-grey) {
  font-family: $PoppinsFont;
  @include font_params($fontSize, $lineHeight, $fontWeight, $color);
}

// BORDERS
@mixin border($color: $border-light, $width: 1px, $type: solid) {
  border: $width $type $color;
}

@mixin border_on($side: 'top', $width: 1px, $color: $border-light, $type: solid) {
  border-#{$side}: $width $type $color;
}

@mixin border_with_radius($width: 1px, $color: $border-light, $type: solid, $radius: $global-border-radius) {
  border: $width $type $color;
  border-radius: $radius;
}

// BUTTONS
@mixin button($fontSize: 14px, $lineHeight: $fontSize * 1.5, $fontWeight: 700, $color: $text-dark-grey) {
  @include poppins_font($fontSize, $lineHeight, $fontWeight, $color);

  padding: 12px 20px 10px;
  border-radius: $global-border-radius;
  cursor: pointer;
  outline: none;
  text-align: center;
}

@mixin button_lato() {
  font-family: $LatoFont;
}

@mixin button_primary($fontSize: 14px, $lineHeight: $fontSize * 1.5, $fontWeight: 700) {
  @include button($fontSize, $lineHeight, $fontWeight, $white);
  @include border($btn-primary);
  @include focus_state(transparentize($btn-primary-hover, 0.3)) {
    border-color: transparentize($white, 0.5);
  }

  background: $btn-primary;

  &:focus {
    color: $white;
  }

  &:hover {
    color: $white;
    background: $btn-primary-hover;
  }

  &:active {
    border-color: $btn-primary-active;
    background-color: $btn-primary-active;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  &[disabled] {
    background: $btn-primary-disabled;
    color: $btn-primary-disabled-text;
    cursor: not-allowed;
    border-color: $btn-primary-disabled;
  }
}

@mixin button_secondary($fontSize: 14px, $lineHeight: $fontSize * 1.5, $fontWeight: 700) {
  @include button($fontSize, $lineHeight, $fontWeight, $link-color);
  @include border;
  @include focus_state;

  background: $white;

  &:hover {
    background: $white;
  }

  &:focus {
    border-color: $border-blue;
  }

  &:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    border-color: $border-light;
  }
}

@mixin button_outline($fontSize: 14px, $lineHeight: $fontSize * 1.5, $fontWeight: 700) {
  @include button($fontSize, $lineHeight, $fontWeight, $link-color);
  @include border($link-color);
  @include focus_state;

  background: $white;

  &:hover {
    background: $white;
  }

  &:active {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}

@mixin button_cta($fontSize: 14px, $lineHeight: $fontSize * 1.5, $fontWeight: 700) {
  @include button($fontSize, $lineHeight, $fontWeight, $text-dark-grey);
  @include border($border-cta);
  @include focus_state(transparentize($border-cta, 0.3)) {
    border-color: transparentize($text-light-grey, 0.5);
  }

  background-color: $background-cta;

  &:hover {
    background-color: $btn-cta_hover;
  }

  &:active {
    border-color: $border-cta;
    background-color: $btn-cta_active;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  &[disabled] {
    background-color: $background-lightest;
    color: $text-grey;
    cursor: not-allowed;
    border-color: $background-lightest;
  }
}

@mixin button_sending($spinner-url, $spinner-size: 15px) {
  position: relative;
  color: transparent !important;

  &::after {
    content: '';
    @include spinner($spinner-url, $spinner-size);
  }
}

@mixin button_link_loading($spinner-url, $spinner-size: 12px) {
  position: relative;
  padding-right: 20px;

  &::after {
    @include spinner($spinner-url, $spinner-size);
    content: '';
    right: 0;
    left: auto;
  }
}

@mixin button_link($fontSize: 14px, $lineHeight: $fontSize * 1.5, $fontWeight: 400) {
  @include lato_font($fontSize, $lineHeight, $fontWeight, $link-color);
  @include focus_state;

  padding: 0;
  background: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
    box-shadow: none;
  }

  &:disabled,
  &:disabled:hover {
    color: $text-light-grey;
  }
}

@mixin button_close($size: 18px, $color: $grey, $width: 1px) {
  @include focus_state;

  position: relative;
  width: $size + 10px;
  height: $size + 10px;
  padding: 0;
  cursor: pointer;
  border-radius: $global-border-radius;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 50%;
    width: $width;
    height: $size + 2px;
    background-color: $color;
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

@mixin button_icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@mixin back_btn($iconUrl, $size: 45px, $backgroundSize: 16px) {
  @include focus_state;
  width: $size;
  height: $size;
  background: url($iconUrl) no-repeat center;
  background-size: $backgroundSize;
}

@mixin round_button($iconUrl, $backgroundColor: transparentize($primary, 0.9), $size: 30px) {
  @include focus_state;
  width: $size;
  height: $size;
  border-radius: #{$size / 2};
  background: $backgroundColor url($iconUrl) no-repeat center;
  background-size: #{$size * 0.4};
}

@mixin button_wide {
  min-width: 240px;

  @include media('mobile') {
    width: 100%;
  }
}

@mixin button_big {
  padding: 16px 25px;
  font-size: 16px;
  text-transform: uppercase;
}

@mixin button_fb($iconUrl: '/src/common/icons/facebook_white.svg') {
  @include button;
  @include border($facebookColor);

  padding: 10px 10px 9px 50px !important;
  background: $facebookColor url($iconUrl) left 10px center no-repeat;
  background-size: 25px;
  border-color: $facebookColor;
  color: #fff !important;
  text-transform: capitalize;
}

@mixin button_messenger($iconUrl: '/src/common/icons/fb_messenger.svg') {
  @include button;
  @include border($messengerColor);

  padding: 10px 10px 9px 50px !important;
  background: $messengerColor url($iconUrl) left 10px center no-repeat;
  background-size: 25px;
  border-color: $messengerColor;
  color: #fff !important;
  text-transform: capitalize;
}

// SPINNER
@mixin spinner($spinner-url: '/src/common/icons/spinner.svg', $spinner-size: 15px, $speed: 0.5s) {
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  width: $spinner-size;
  height: $spinner-size;
  top: calc(50% - #{$spinner-size / 2});
  left: calc(50% - #{$spinner-size / 2});
  transform: rotate(0deg);
  background: url($spinner-url) no-repeat center;
  animation: spinner $speed linear infinite;
  background-size: $spinner-size;
  will-change: transform, opacity;
}

// Check mark aka 'tick'
@mixin check_mark($color: $dark-grey, $size: 4px) {
  &::before {
    content: '';
    display: inline-block;
    width: $size;
    height: $size * 2;
    margin-right: 15px;
    margin-bottom: 1px;
    border: 2px solid $color;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

// FORM CONTROLS
@mixin default_textarea(
  $fontSize: 15px,
  $lineHeight: 20px,
  $color: $text-dark-grey,
  $minHeight: 100px,
  $maxHeight: 230px,
  $resize: vertical
) {
  @include lato_font($fontSize, $lineHeight, 400, $color);
  @include border;
  background: $white;
  min-height: $minHeight;
  max-height: $maxHeight;
  resize: $resize;
  width: 100%;
  border-radius: 4px;
  padding: 6px 12px;

  &:focus {
    outline: none;
  }
}

@mixin link($fontSize: 15px, $lineHeight: $fontSize * 1.5, $fontWeight: 400) {
  @include lato_font($fontSize, $lineHeight, $fontWeight, $link-color);
  @include focus_state;

  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-color-hover;
  }

  &:active {
    color: $link-color-active;
    box-shadow: none;
  }
}

@mixin macScrollBars() {
  @include min_media('tablet') {
    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.07);
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.15);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(0, 0, 0, 0.03);
    }
  }
}

@mixin input {
  @include border;
  padding: 9px 10px 10px;
  border-radius: $global-border-radius;
  line-height: 19px;
  color: #414141;
}

@mixin container {
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 15px;
}

@mixin hidden_mobile {
  @include media('mobile') {
    display: none;
  }
}

@mixin settings_block($desktopPadding: 35px 35px, $mobilePadding: 25px) {
  background: $white;
  border-radius: $global-border-radius;
  padding: $desktopPadding;
  margin-bottom: 20px;

  @include media('mobile') {
    padding: $mobilePadding;
  }
}

@mixin box_size($size) {
  width: $size;
  height: $size;
  max-width: $size;
  max-height: $size;
}

@mixin avatar($size: 150px, $withFallback: false) {
  @include border(transparentize($border-light, 0.3));
  @include box_size($size);
  border-radius: 50%;

  @if $withFallback {
    @include avatar_fallback;
  }
}

@mixin avatar_fallback {
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #eee url('https://cdn.fixly.prd.01.eu-west-1.eu.olx.org/avatar/avatar.png') center / cover;
  }
}

@mixin tooltip($width: null, $verticalMargin: 10px, $arrowSize: 12px) {
  @if $width {
    left: calc(50% - #{$width} / 2);
    min-width: $width;
  } @else {
    left: 'auto';
  }

  position: absolute;
  bottom: calc(100% + #{$verticalMargin});
  transform: translateY(10px);
  transition: 0.3s;
  opacity: 0;
  z-index: 10;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    bottom: -5px;
    position: absolute;
    right: calc(50% - #{$arrowSize} / 2);
    width: $arrowSize;
    height: $arrowSize;
    transform: rotate(45deg);
    background: #ffffff;
  }

  &::before {
    @include border($border-dark);
    border-top: none;
    border-left: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: -1;
  }

  &_bottom {
    top: calc(100% + #{$verticalMargin});

    &::before,
    &::after {
      top: -7px;
      transform: rotate(-135deg);
    }
  }
}

@mixin tooltip_content_wrapper() {
  @include border;

  position: relative;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  white-space: normal;
  word-break: break-word;
  text-transform: none;
}

@mixin error_text() {
  @include lato_font(13px, 16px, 400, $error);
  text-align: center;
}

// Header dropdown

$trans-dur: 0.2s;
$trans-delay: 0.06s;

@mixin dropdown_wrapper() {
  position: absolute;
  top: calc(100% - 1px);
  right: -30px;
  z-index: 13;
  padding: 0 10px 10px;
  transition: 0s;

  &_hidden {
    z-index: -1;
    transform: scale(0);
    transition-delay: $trans-dur + $trans-delay;
  }

  @include media('tablet') {
    position: fixed;
    top: $mobile-header-height;
    right: 0;
    height: calc(100% - #{$mobile-header-height});
    width: 100%;
    padding: 0;
  }
}

@mixin dropdown($background: $white, $borderColor: $border-light) {
  @include border($borderColor);

  width: 400px;
  margin: 0;
  padding: 0;
  position: relative;

  background: $background;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  opacity: 1;
  transform: none;
  transition: $trans-dur;
  transition-delay: $trans-delay;

  &::before {
    $triangleSize: 16px;

    @include border_on('bottom', 1px, $borderColor);
    @include border_on('right', 1px, $borderColor);

    content: '';
    position: absolute;
    right: 48px;
    top: -$triangleSize / 2 - 1px;
    width: $triangleSize;
    height: $triangleSize;
    transform: rotate(-135deg);
    background: $background;
    z-index: 0;
  }

  @include media('tablet') {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-flow: column;
    border-radius: 0;
    border-color: #fff;

    &::before {
      content: unset;
    }
  }
}

@mixin dropdown_hidden {
  opacity: 0;
  transform: translateY(10px);
  transition-delay: $trans-delay;

  @include media('tablet') {
    transform: translateX(10px);
  }
}

// Dropdown arrow

@mixin dropdown_arrow($color: $primary, $hoverColor: $dark-blue) {
  &::after {
    @include border($color, 2px);
    content: '';
    display: block;
    position: absolute;
    right: 15px;
    top: 30px;
    width: 6px;
    height: 6px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: transform 0.2s;
  }

  &:hover::after {
    border-color: $hoverColor;
    transform: rotate(45deg) translate(2px, 2px);
  }
}

@mixin seo_text_styles {
  h2,
  h3 {
    @include poppins_font(18px, 32px, 500);
    margin: 10px 0 15px;
  }

  h4 {
    @include poppins_font(16px, 28px, 500);
    margin: 10px 0 15px;
  }

  p {
    margin-bottom: 25px;
  }

  ul {
    list-style: initial;
    padding-left: 16px;
    margin-bottom: 25px;
  }

  ol {
    padding-left: 12px;
    margin-bottom: 25px;

    li {
      padding-left: 3px;
    }
  }

  blockquote {
    padding: 0 0 0 25px;
    margin: 0 0 25px 0;
    border: none;
    font-size: 14px;

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }

    p {
      display: inline;
    }
  }

  br {
    content: '';
    display: block;
    margin-top: 10px;
  }
}

@mixin notification_count($background-color: $badge-color, $border-color: #fff) {
  @include border($border-color);
  @include lato_font(10px, 14px, 700, #fff);
  height: 16px;
  min-width: 16px;
  border-radius: 8px;
  background: $background-color;
  text-align: center;
}
