@import "src/_mixin.scss";

.topCategories {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 550px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  @include media('mobile') {
    max-height: none;
  }

  &_inline {
    flex-wrap: nowrap;
    overflow: scroll;
  }
  &_dark {
    .topCategories {
      &__categoryLink {
        color: $white;
      }

      &__category {
        &:hover,
        &:hover .topCategories__categoryLink {
          color: $silver-chalice;
          svg path {
            fill: $silver-chalice;
          }
        }
        svg path {
          fill: $white;
        }
      }
    }
  }

  &__category {
    flex: 0 0;
    display: inline-block;
    vertical-align: top;

    svg {
      vertical-align: middle;
      margin-right: 30px;

      path {
        fill: $primary;
        transition: fill 0.2s;
      }
    }

    &:hover {
      svg path {
        fill: $dark-grey;
      }
    }

    &_highlighted {
      @include media('mobile') {
        order: -1;
      }
      .topCategories__categoryName > span {
        border-bottom: solid 2px $cta;
      }
    }
  }

  &_homepage &__category {
    width: 32%;

    &:nth-child(odd) {
      margin-right: 1%;
    }

    @include media('mobile') {
      width: 100%;
      padding: 0 25px;
    }
  }

  &_homepage &__categoryLink {
    @include media('mobile') {
      padding-top: 13px;
      padding-bottom: 9px;
      padding-right: 0;
    }
  }

  &_homepage &__categoryName {
    @include media('mobile') {
      flex: 1;
    }
  }

  &__arrowIcon {
    display: none;

    @include media('mobile') {
      display: block;
      width: 25px;
      height: 25px;
      margin-top: -5px;
    }
  }

  &_inline &__category {
    flex-basis: auto;

    svg {
      margin-right: 0;
    }

    @include media('mobile') {
      flex: 1 1;
    }
  }

  &__categoryLink {
    @include lato_font(16px, 24px, 700, $link-color);
    @include focus_state;
    display: flex;
    align-items: center;
    padding: 14px;
    transition: color 0.2s;
    border-radius: $global-border-radius;

    &_active svg path {
      fill: $dark-grey;
    }
  }

  &_inline &__categoryLink {
    flex-direction: column;
    align-items: center;
    padding: 5px;
    color: $text-grey;

    @include media('mobile') {
      @include font_params(12px, 16px, 400, $text-grey);
      display: block;
      text-align: center;
      max-width: 110px;
      min-width: 60px;
    }
  }

  &__category:hover &__categoryLink {
    color: $dark-grey;
  }

  &__categoryName {
    margin-top: -1px;
    > span {
      padding-bottom: 2px;
    }
  }

  &__categoryIcon {
    @include media('mobile') {
      display: block;
    }
  }

  &_inline &__categoryName {
    display: block;
    margin-top: 4px;
    text-align: center;
  }
}
